* {
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
}

#root {
    height: 100%;
}


body {
    margin: 0;
    font-family: "JosefinSans", "sans-serif";
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: white;
    height: 100%;
}

@font-face {
    font-family: 'JosefinSans';
    src: local('JosefinSans'), url(./fonts/Josefin_Sans/JosefinSans-VariableFont_wght.ttf) format('opentype');
}

@font-face {
    font-family: 'JosefinSansLight';
    src: local('JosefinSansLight'), url(./fonts/Josefin_Sans/static/JosefinSans-Light.ttf) format('opentype');
}

:root {
    --content-width: 1110px;
}

a {
    color: white;
    text-decoration: none;
}

.container {
    position: relative;
}

@media screen and (max-width: 1000px){
    .container {
        padding-bottom: 90px;
        box-sizing: border-box;
    }
}

.inner-container {
    width: 100%;
    height: auto;
}

.header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 300px;
}

.header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 300px;
}

.header-mobile {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: auto;
}


.content {
    position: absolute;
    width: var(--content-width);
    top: 240px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 1000px) {
    .content {
        max-width: 100%;
        position: initial;
        top: 52px;
        padding-left: 5px;
        padding-right: 5px;
        box-sizing: border-box;
    }
}

@media (max-width: 1280px) {
    .content {
        top: 200px;
    }
}

@media (min-width: 1620px) {
    .content {
        top: 400px;
    }
}

.content-relative {
    width: var(--content-width);
    margin: 0 auto;
    margin-top: -550px;
    position: relative;
}

@media screen and (max-width: 1000px) {
    .content-relative {
        display: block;
        width: 100%;
        margin: 0 auto;
        padding: 0 5px;
        box-sizing: border-box;
    }
}

@media screen and (min-width: 1620px) {
    .content-relative {
        margin-top: -750px;
    }
}

.content-relative-inner {
    width: var(--content-width);
    margin: 0 auto;
    position: relative;
}

@media screen and (max-width: 1000px) {
    .content-relative-inner {
        display: block;
        width: 100%;
        margin: 0 auto;
        padding: 0 5px;
        box-sizing: border-box;
    }
}

.header-caption {
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 48px;
    color: #FFFFFF;
}

@media screen and (max-width: 1000px){
    .header-caption {
        font-size: 26px;
        line-height: 36px;
        color: #000000;
        text-align: center;
    }
}

.lots-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media screen and (max-width: 1000px) {
    .lots-container {
        display: block;
    }
}

.top-bar {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: white;
    border-bottom: 1px solid #887CE1;
}

.top-bar-inner {
    width: var(--content-width);
    height: 50px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
}

.top-bar-inner > div:nth-child(1) {
    width: 45%;
    display: flex;
    justify-content: space-between;
}

.top-bar-inner > div:nth-child(2) {
    width: 22%;
    display: flex;
    justify-content: space-between;
}

.top-bar-inner-half {
    display: flex;
    justify-content: space-between;
    width: 48%;
}

.top-bar-item {
    display: flex;
    align-items: center;
    height: 19px;
}

.top-bar-item > a {
    margin-left: 5px;
    margin-top: 3px;
}

.top-bar-icons-container {
    max-width: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.top-bar-icons-container > div {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.main-menu-container {
    width: var(--content-width);
    height: 100px;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    color: white;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
}

.main-menu {
    margin-left: 2%;
}

.main-menu > li {
    display: inline;
    padding: 0 25px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
}

.main-menu > li > a {
    color: white;
    text-decoration: none;
}

.main-menu-dropdown {
    position: relative;
    display: inline-block;
}

.main-menu-dropdown-content {
    display: none;
    position: absolute;
    background: #452DB9;
    box-sizing: border-box;
    border-radius: 10px;
    width: 534px;
    height: 392px;
    padding: 33px 47px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.main-menu-dropdown:hover .main-menu-dropdown-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.main-menu-dropdown-content-item {
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: left;
    width: 45%;
    cursor: pointer;
    font-size: 18px !important;
}

.main-menu-dropdown-content-item > img {
    width: 25px;
    height: 25px;
    margin-right: 25px;
}


.cursor-pointer {
    cursor: pointer;
}

.logo {
    cursor: pointer;
    width: 407px;
    height: 100px;
    background: url("./images/logo.png") no-repeat;
    background-size: contain;
}

.logo-landing {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: -200px;
}

@media screen and (max-width: 1000px) {
    .logo {
        margin: 0 auto;
        margin-top: 30px;
        width: 241px;
        height: 64px;
    }
    .logo-landing {
        top: 10px;
    }
}

.home-header-content {
    color: white;
}

.home-header-content > div:nth-child(1) {
    color: #00F7FF;
    font-size: 36px;
    line-height: 46px;
}

@media screen and (max-width: 1000px) {
    .home-header-content > div:nth-child(1) {
        color: #3F3356;
        font-size: 36px;
        line-height: 40px;
        text-align: center;
    }

    .home-header-content > div:nth-child(2) {
        color: #3F3356;
        font-weight: 300 !important;
        font-size: 20px !important;
        line-height: 28px !important;
        text-align: center;
        margin-top: 27px;
    }

    .home-header-buttons {
        margin-top: 24px;
        margin-bottom: 32px;
        text-align: center;
    }

    .home-header-buttons > div:nth-child(1) {
        margin-bottom: 24px;
    }
}

@media (max-width: 1200px) {
    .home-header-content > div:nth-child(1) {
        font-size: 36px;
        line-height: 40px;
    }

    .home-header-content > div:nth-child(2) {
        font-weight: 300 !important;
        font-size: 20px !important;
        line-height: 28px !important;
    }

    .home-header-buttons {
        margin-top: 24px;
        margin-bottom: 32px;
    }

    .home-header-buttons > div:nth-child(1) {
        margin-bottom: 24px;
    }
}

.home-header-content > div:nth-child(2) {
    font-size: 72px;
    line-height: 70px;
    font-weight: 600;
    margin-top: 29px;
}

.home-header-content > div:nth-child(3) {
    font-size: 24px;
    line-height: 34px;
    margin-top: 28px;
}

.popular-categories-container {
    margin-top: 100px;
    color: black;
}

.popular-categories-container > div:nth-child(1) {
    font-size: 36px;
    line-height: 36px;
    text-align: center;
}

.popular-categories-container > div:nth-child(2) {
    display: flex;
    justify-content: space-between;
    margin-top: 49px;
}

@media screen and (max-width: 1000px) {
    .popular-categories-container > div:nth-child(1) {
        font-size: 25px;
        line-height: 25px;
    }

    .popular-categories-container > div:nth-child(2) {
        flex-wrap: wrap;
        max-width: 100%;
    }
}

@media screen and (min-width: 1920px) {
    .popular-categories-container {
        margin-top: 170px;
    }
}

.category-item {
    width: 160px;
    height: 187px;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
}

@media screen and (max-width: 1000px) {
    .category-item {
        width: 111px;
        height: 130px;
        margin-bottom: 10px;
    }
}

.category-item:hover {
    box-shadow: 4px 4px 5px rgb(0 0 0 / 5%);
}

.category-item-inner {
    margin: 0 auto;
    text-align: center;
}

.category-item-inner > div {
    text-align: center;
    color: #1F1165;
    font-size: 14px;
    line-height: 28px;
    margin-top: 14px;
    text-transform: uppercase;
}

.category-item-icon {
    width: 75px;
    height: 75px;
    margin: 0 auto;
}

.center-absolute {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}


.popular-categories-detailed-container {
    margin-top: 300px;
}

@media screen and (max-width: 1000px) {
    .popular-categories-detailed-container {
        margin-top: 37px;
    }
}

.popular-categories-detailed-item-container {
    position: relative;
    margin-bottom: 110px;
}

@media screen and (max-width: 1000px) {
    .popular-categories-detailed-item-container {
        position: initial;
    }
}


.category-item-detailed {
    width: var(--content-width);
    height: 750px;
    margin: 0 auto;
}

@media screen and (max-width: 1000px) {
    .category-item-detailed {
        max-width: 100%;
        height: auto;
    }
}


.category-item-detailed-bg {
    position: absolute;
    width: 100%;
    object-fit: contain;
    bottom: 0;
    z-index: -1;
}

.category-item-detailed-content {

}

.category-item-detailed-content > div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 1000px) {
    .category-item-detailed-content > div:nth-child(1) {
        display: block;
        max-width: 100%;
    }
}

.category-item-detailed-content > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1106px;
    margin-top: 40px;
}

@media screen and (max-width: 1000px) {
    .category-item-detailed-content > div:nth-child(2) {
        display: block;
        max-width: 100%;
        margin-top: 15px;
    }
}


.category-item-detailed-icon-container {
    display: flex;
    align-items: center;
}

.category-item-detailed-icon {
    width: 75px;
    height: 75px;
}

.category-item-detailed-caption-container {
    margin-left: 20px;
}

.category-item-detailed-caption {
    font-size: 50px;
    line-height: 50px;
}

@media screen and (max-width: 1000px) {
    .category-item-detailed-caption-container {
        margin: 0 auto;
    }

    .category-item-detailed-caption {
        font-size: 18px;
        line-height: 20px;
        text-align: center;
    }
}

.category-item-detailed-description {
    font-size: 16px;
    max-width: 300px;
}

.register-landing-box {
    background: url("./images/register-landing-box.png");
    background-size: contain;
    width: var(--content-width);
    height: 277px;
    margin: 0 auto;
    margin-bottom: 110px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 35px;
}

.register-landing-box > div:nth-child(1) {
    max-width: 413px;
}

.register-landing-box > div:nth-child(1) > div:nth-child(1) {
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    color: #FFFFFF;
}

.register-landing-box > div:nth-child(1) > div:nth-child(2) {
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
    margin-top: 11px;
}

@media screen and (max-width: 1000px) {
    .register-landing-box {
        max-width: 100%;
    }
}

.lot-item {
    width: 350px;
    height: 547px;
    background: white;
    border: 1px solid #F7F5F9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 32px;
}

@media screen and (max-width: 1000px) {
    .lot-item {
        width: 100%;
        margin: 0 auto;
        box-shadow: none;
        border: none;
        border-bottom: 1px solid #F7F5F9;
    }
}

.lot-item-clickable {
    cursor: pointer;
}

.lot-item-image-container {
    width: 100%;
    height: 237px;
    background: #F6F6FF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 22px;
    box-sizing: border-box;
    position: relative;
}

.lot-item-image-container > img {
    max-width: 100%;
    max-height: 100%;
}

.lot-item-short-description {
    color: #171D1C;
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    margin: 13px;
    height: 40px;
    cursor: pointer;
}

.lot-item-info-container {
    width: 100%;
    height: 88px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    border-top: 1px dashed #D6D5F3;
    border-bottom: 1px dashed #D6D5F3;
}

.lot-item-info-container > div {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 16px;
    box-sizing: border-box;
}

.lot-item-info-container > div:nth-child(1) {
    border-right: 1px solid #BFBEE8;
}

.lot-item-info-container > div:nth-child(2) {
    padding-left: 10px;
}

.lot-item-price-container {
    text-align: right;
}

.lot-item-price-container > div:nth-child(1) {
    font-size: 14px;
    line-height: 24px;
}

.lot-item-price-container > div:nth-child(2) {
    font-size: 20px;
    line-height: 28px;
}

.lot-item-info-icon {
    width: 30px;
    height: 30px;
}

.lot-item-timer-container {
    display: flex;
    color: #6979F8;
    font-size: 18px;
    line-height: 28px;
    margin-top: 13px;
}

.lot-item-timer {
    width: 57%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-right: 1px solid #BFCBCB;
}

.lot-item-audobid-button {
    text-align: center;
    margin: 0 auto;
}

.lot-item-submit-button {
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.signup-container {
    background: #FEFEFF;
    box-shadow: -6.07769px -34.4683px 40px rgba(44, 46, 69, 0.0901961);
    border-radius: 10px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

@media screen and (max-width: 1000px) {
    .signup-container {
        box-shadow: none;
    }
}

.login-container {
    background: #FEFEFF;
    box-shadow: -6.07769px -34.4683px 40px rgba(44, 46, 69, 0.0901961);
    border-radius: 10px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

@media screen and (max-width: 1000px) {
    .login-container {
        box-shadow: none;
    }
}

.login-form {
    width: 672px;
    height: auto;
    padding-top: 56px;
    box-sizing: border-box;
    text-align: center;
}

@media screen and (max-width: 1000px){
    .login-form {
        padding-top: 0;
    }
}

.signup-form {
    width: 672px;
    height: auto;
    padding-top: 56px;
    box-sizing: border-box;
    text-align: center;
}

@media screen and (max-width: 1000px){
    .signup-form {
        padding-top: 0;
    }
}

.signup-form-terms > label > span {
    font-size: 14px;
    line-height: 28px;
    font-family: "JosefinSans", "sans-serif";
}

.signup-form > div > a {
    color: #432EB9;
}

.signup-caption {
    font-size: 50px;
    line-height: 28px;
    font-weight: 700;
    color: #171D1C;
    text-transform: uppercase;
}

@media screen and (max-width: 1000px){
    .signup-caption {
        font-size: 40px;
        line-height: 40px;
        font-weight: 600;
        text-transform: none;
    }
}

.signup-description {
    margin-top: 20px;
    margin-bottom: 55px;
    font-size: 18px;
    line-height: 28px;
    color: #3E504E;
}

.signup-form-inputs > div {
    margin-bottom: 30px;
    box-sizing: border-box;
    height: 60px;
}

.signup-form-button {
    margin-top: 30px;
    margin-bottom: 30px;
}

.signup-form-login {
    font-size: 18px;
}

.signup-form-login > a {
   color: #432EB9;
}

.footer-container {
    position: relative;
    height: auto;
}

.footer-container > img {
    position: absolute;
    bottom: 0;
}

.footer {
    background: url("images/footer.jpg") no-repeat;
    background-size: cover;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: 200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: auto;
    color: #D9DDFF;
}

.footer > img {
    z-index: -1;
}

.footer-inner-container {
    max-width: var(--content-width);
    margin: 0 auto;
}

.footer-menu-caption {
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 18px;
    color: #FFFFFF;
}

.footer-menu {
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
}

.footer-menu > div > ul {
    list-style-type: none;
}

.footer-menu > div > ul > li {
    font-size: 18px;
    line-height: 36px;
    align-items: center;
    display: flex;
}

.footer-menu-icon {
    margin-right: 7px;
}

.icon-send {
    transform: rotate(-45deg);
}

.footer-menu > div > ul > li > a {
    font-size: 18px;
    line-height: 36px;
}

.footer-menu > div > ul > li > a:hover {
    color: #FFFFFF !important;
}

.footer-menu > div > ul > li > a {
    color: #D9DDFF !important;
}

.footer-bottom-container {
    position: relative;
    display: flex;
    align-items: center;
    border-top: 1px solid #D9DDFF;
    margin-top: 30px;
    height: 113px;
}

.payment-icons {
    display: flex;
    justify-content: space-between;
    width: 204px;
    margin: 0 auto;
}

.payment-icons > img {
    width: 36px;
}

.copyright {
    position: absolute;
    right: 0;
    color: #D9DDFF;
    font-size: 18px;
    line-height: 36px;
}

.footer-box {
    display: flex;
    align-items: center;
    justify-content: right;
    width: var(--content-width);
    height: 318px;
    background: #FFFFFF;
    box-shadow: 0.487477px 3.97018px 81px rgba(40, 58, 140, 0.458824);
    border-radius: 20px;
    position: relative;
}

.footer-box > img {
    position: absolute;
    height: 400px;
    bottom: 0;
    left: -87px;
}

.footer-box-content {
    width: 482px;
    height: 186px;
    margin-right: 60px;
}

.footer-box-content > div:nth-child(1) {
    color: #6940F5;
    font-size: 24px;
    font-weight: 600;
    line-height: 38px;
    margin-bottom: 12px;
}

.footer-box-content > div:nth-child(2) {
    color: #171D1C;
    font-size: 36px;
    line-height: 46px;
    font-weight: 600;
    margin-bottom: 12px;
}

.footer-box-content > div:nth-child(3) {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.MuiBottomNavigation-root {
    background-color: #5D40BD !important;
    color: white;
}

.MuiBottomNavigation-root > button {
    color: #FFFFFF !important;
    min-width: 70px !important;
}



.lot-details-container {

}

.lot-carousel-container {
    margin-top: 60px;
}

.lot-carousel-main-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 585px;
    width: 100%;
    background: #F6F6FF;
    border-radius: 10px;
    z-index: 100;
}

.lot-carousel-main-image > img {
    max-width: 100%;
    max-height: 100%;
}

@media screen and (max-width: 1000px){
    .lot-carousel-main-image {
        max-width: 100%;
        max-height: 198px;
    }
    .lot-carousel-main-image > img {
        max-height: 198px;
    }
}

.lot-details {
    margin-top: 77px;
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 1000px){
    .lot-details {
        margin-top: 20px;
        display: block;
        width: 100%;
    }
}

.lot-details-id {
    font-size: 18px;
    line-height: 28px;
    color: #808988;
}

@media screen and (max-width: 1000px){
    .lot-details-id {
        font-size: 10px;
        line-height: 14px;
        color: #808988;
        margin-top: 5px;
        margin-bottom: 15px;
    }
}

.lot-details-left-container {
    width: 732px;
}

@media screen and (max-width: 1000px){
    .lot-details-left-container {
        width: 100%;
    }
}

.lot-details-right-container {
    width: 350px;
    height: 486px;
}

.lot-details-right-container > div:nth-child(1) {
    background: rgba(73, 57, 192, 0.0001);
    border: 1px solid rgba(101, 93, 202, 0.301961);
    box-sizing: border-box;
    border-radius: 30px;
    height: 428px;
    padding: 35px 28px;
}


.lot-details-caption {
    color: #171D1C;
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
}

@media screen and (max-width: 1000px){
    .lot-details-caption-container {
        margin: 0 auto;
    }
    .lot-details-caption {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        color: #171D1C;
    }
}


.lot-details-price-container {
    background: #EBF2FF;
    border-radius: 10px;
    height: 195px;
    width: 100%;
    box-sizing: border-box;
    padding: 40px 38px;
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
}

.lot-details-price-container > div:nth-child(1) > div:nth-child(1) {
    color: #171D1C;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
}

.lot-details-price-container > div:nth-child(1) > div:nth-child(2) {
    color: #4C5C59;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 13px;
    margin-top: 20px;
}

.lot-details-price-container > div:nth-child(1) > div:nth-child(3){
    color: #4C5C59;
    font-size: 18px;
    line-height: 24px;
}

.lot-details-price-container > div:nth-child(2) > div:nth-child(1){
    color: #43B055;
    font-weight: 600;
    font-size: 36px;
    line-height: 34px;
}

.lot-details-price-container > div:nth-child(2) > div:nth-child(2){
    color: #171D1C;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 13px;
    margin-top: 20px;
}

.lot-details-price-container > div:nth-child(2) > div:nth-child(3){
    color: #171D1C;
    font-size: 24px;
    line-height: 24px;
}

.lot-details-price-container > div:nth-child(2) {
    text-align: right;
}

.lot-details-right-caption {
    text-align: center;
    font-size: 18px;
    line-height: 28px;
    color: #323636;
}

.lot-details-timer {
    border-bottom: 1px solid #CEC8F9;
    height: 75px;
    width: 100%;
    margin-top: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lot-details-timer-item {

}

.lot-details-timer-item > div:nth-child(1) {
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    color: #EC6624;
}

.lot-details-timer-item > div:nth-child(2) {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #4C5C59;
}

.lot-details-timer-colon {
    height: 82px;
}

.lot-details-right-bottom-container {

}

.lot-details-right-bottom-item {
    height: 68px;
    display: flex;
    align-items: center;
    margin-top: 20px;
}

@media screen and (max-width: 1000px) {
    .lot-details-right-bottom-item {
        background: rgba(196, 196, 196, 0.1);
        padding: 19px 0;
    }
    .lot-details-right-bottom-item > div > div:nth-child(1) {
        font-weight: 400 !important;
        font-size: 18px !important;
        line-height: 24px !important;
        color: #171D1C !important;
    }

    .lot-details-right-bottom-item > div > div:nth-child(2) {
        font-weight: 600 !important;
        font-size: 24px !important;
        line-height: 24px !important;
        color: #4C5C59 !important;
        margin-top: 8px;
    }
}

.lot-details-right-bottom-item > img {
    width: 45px;
    height: 45px;
    margin-right: 16px;
}

.lot-details-right-bottom-item > div > div:nth-child(1) {
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    color: #171D1C;
}

.lot-details-right-bottom-item > div > div:nth-child(2) {
    font-size: 18px;
    line-height: 24px;
    color: #4C5C59;
}

.blue-link {
   color: #00ACFF !important;
}

.lot-details-right-link-container {
    text-align: center;
    font-size: 18px;
    line-height: 28px;
    margin-top: 24px;
}

.lot-details-buttons-container {
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    border-top: 1px solid #E0E0F1;
    border-bottom: 1px solid #E0E0F1;
    box-sizing: border-box;
    padding-right: 30px;
}

.lot-details-buttons-container > img {
    width: 65px;
    height: 65px;
    margin-right: 100px;
}

.lot-details-tabs-container {
    width: 100%;
    height: 103px;
    background: #EBF2FF;
    margin-top: 109px;
    margin-bottom: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

@media screen and (max-width: 1000px) {
    .lot-details-tabs-container {
        background: none !important;
        height: 80px;
        margin: 0 auto;
        margin-top: 24px;
        margin-bottom: 63px;
        max-width: 304px;
    }
}

.lot-details-tabs {
    width: var(--content-width);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}

@media screen and (max-width: 1000px) {
    .lot-details-tabs {
        width: 100%;
    }
}

.lot-details-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(0, 0, 64, 0.0001);
    border: 1px solid rgba(101, 93, 202, 0.301961);
    box-sizing: border-box;
    border-radius: 10px;
    height: 63px;
    padding: 10px 22px;
    font-weight: 600;
    font-size: 18px;
    line-height: 46px;
    color: #323636;
    cursor: pointer;
    position: relative;
}

@media screen and (max-width: 1000px) {
    .lot-details-tab {
        width: 61px;
        height: 61px;
        background: #EBF2FF;
        border-radius: 6px;
        justify-content: center;
    }
}

.lot-details-tab-active {
    box-shadow: 0.707107px 0.707107px 10px rgba(55, 34, 154, 0.101961);
    background: #FFFFFF !important;
}

@media screen and (max-width: 1000px) {
    .lot-details-tab-active {
        background: #EBF2FF !important;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
}

.lot-details-tab > img {
    width: 35px;
    height: 35px;
    margin-right: 30px;
}

@media screen and (max-width: 1000px) {
    .lot-details-tab > img {
        margin: 0 auto !important;
    }
}

.lot-details-tab-absolute {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}

.faq {
    /*background: #FFFFFF;*/
    /*border-radius: 10px;*/
    box-sizing: border-box;
    min-height: 500px;
}
.faq > div {
    background-color: #EBF2FF;
}

.faq-icon {
    width: 40px;
    height: 40px;
    min-width: 40px;
    background: url("./images/faq.png");
    background-size: cover;
    margin-right: 20px;
}

@media screen and (max-width: 1000px){
    .faq-icon {
        width: 30px;
        height: 30px;
        min-width: 30px;
        margin-right: 10px;
    }
}

.faq-caption {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #323636;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1000px){
    .faq-caption {
        font-size: 16px;
        line-height: 24px;
    }
}


.faq-details {
    font-size: 18px;
    line-height: 28px;
    color: #323636;
}

@media screen and (max-width: 1000px){
    .faq-details {
        font-size: 14px;
        line-height: 24px;
    }
}

.faq-details > p {
    margin-bottom: 10px;
}

.copy-icon {
    width: 20px;
    height: 20px;
}

.tab-container {
    box-sizing: border-box;
    border-bottom: 1px solid #D7D9F5;
}

.tab-buttons-container {
    display: flex;
    align-items: center;
}

.tab-button {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #37406F;
    margin-right: 75px;
    cursor: pointer;
    height: 40px;
}

.tab-button.active {
    border-bottom: 3px solid #F7447B;
    box-sizing: border-box;
}

.tab-content {
    margin-top: 20px;
    font-size: 16px;
    line-height: 38px;
    color: #37406F;
}

.table > tbody > tr > td {
    font-family: JosefinSans, serif;
    font-size: 16px !important;
    line-height: 26px !important;
    color: #37406F !important;
    border-bottom: 1px solid #D7D9F5 !important;
    cursor: pointer !important;
}

.table > thead > tr > th {
    font-family: JosefinSans, serif;
    font-size: 16px !important;
    line-height: 26px !important;
    color: #37406F !important;
    border-bottom: 1px solid #D7D9F5 !important;
}

@media screen and (max-width: 1000px){
    .table > thead > tr > th, .table > tbody > tr > td {
        padding: 6px 0 !important;
    }
    .table > tbody > tr > td:nth-child(1) {
        max-width: 10% !important;
    }
}

.table-pagination > div > p {
    font-family: JosefinSans, serif;
    font-size: 16px !important;
    line-height: 26px !important;
    color: #37406F !important;
}

.status-completed {
    color: #43B055;
    font-size: 16px;
}

.status-canceled{
    color: #FF0205;
    font-size: 16px;
}

.fav-icon-container {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.fav-icon-container > img {
    width: 20px;
    height: 20px;
}

.caption {
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    color: #37406F;
    margin-bottom: 20px;
}

.center {
    text-align: center;
    margin: 0 auto;
}

@media screen and (max-width: 1000px) {
    .caption {
        font-size: 22px;
        line-height: 40px;
        text-align: center;
    }
}


.modal {
    background: #452DB9;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 10px;
    color: #FFFFFF;
    font-size: 22px;
    line-height: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    outline: none;
    width: auto;
    height: auto;
    padding: 30px;
    box-sizing: border-box;
}

@media screen and (max-width: 1000px){
    .modal {
        padding: 10px;
        width: 95% !important;
        height: auto !important;
    }
}

.modal.autobidder {
    width: 534px;
    height: 363px;
}

.modal.autobidder.deactivate {
    width: 534px;
    height: auto;
}

.modal-autobidder-content-deactivate {
    width: 397px;
    text-align: center;
    margin: 0 auto;
    margin-top: 30px;
}

.modal-close-button {
    position: absolute;
    right: 15px;
    top: 5px;
    cursor: pointer;
}

.modal-caption {
    text-align: center;
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 30px;
}

.modal-reset-password-from {
    margin-top: 10px;
}

.modal-button-container {
    text-align: center;
    margin-top: 30px;
}

@media screen and (max-width: 1000px){
    .modal-caption {
        font-size: 24px;
        line-height: 34px;
        margin-bottom: 10px;
    }
}

.modal-autobidder-balance {
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
}

@media screen and (max-width: 1000px){
    .modal-autobidder-balance {
        margin-bottom: 10px;
        font-size: 20px;
    }
}

.modal-autobidder-form {
    display: flex;
    justify-content: space-between;
}

.modal-autobidder-form > div {
    max-width: 30%;
    box-sizing: border-box;
    position: relative;
}

@media screen and (max-width: 1000px){
    .modal-autobidder-form {
        display: block;
        text-align: center;
    }
    .modal-autobidder-form > div {
        max-width: 100%;
        width: 138px;
        margin: 0 auto;
        margin-bottom: 10px;
    }
}

.modal-autobidder-form-input {
    font-family: JosefinSans, serif;
    font-size: 22px;
    height: 35px;
    width: 138px;
    padding: 20px 8px 20px 35px;
    color: #979696;
    outline: none;
    box-sizing: border-box;
    text-align: right;
    border-radius: 3px;
    border: none;
}

.modal-autobidder-form-input:focus {
    height: 35px;
    width: 138px;
    box-sizing: border-box;
    border: none !important;
    outline: none;
}

@media screen and (max-width: 1000px){
    .modal-autobidder-form-input {
    }
}

.modal-autobidder-form-input-label {
    margin-bottom: 7px;
    font-size: 22px;
    line-height: 22px;
    text-align: center;
}

@media screen and (max-width: 1000px){
    .modal-autobidder-form-input-label {
        margin-bottom: 2px;
        font-size: 16px;
        line-height: initial;
    }
}

.modal-autobidder-form-button-container {
    text-align: center;
    margin-top: 38px;
}

.adornment-icon {
    position: absolute;
    bottom: 9px;
    left: 3px;
    color: #979696;
}

.input {
    height: 36px;
    width: 100%;
    font-size: 18px;
    line-height: 28px;
    box-sizing: border-box;
    border: none !important;
    outline: none;
    background: #F7F5F9;
    border-radius: 18px;
    color: #37406F;
    padding-left: 16px;
    padding-right: 16px;
}

.link {
    color: #432EB9;
    cursor: pointer;
}

.loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.landing-box {
    color: white;
    font-size: 48px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: -20px;
    width: 905px;
    padding: 50px 30px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    text-align: center;
}

@media screen and (max-width: 1000px){
    .landing-box {
        width: 90%;
        padding: 20px;
        top: 200px;
        font-size: 18px;
    }
}

.landing-faq {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 500px;
    color: #303030;
    font-size: 24px;
    width: 420px;
    text-align: center;
    cursor: pointer;
}

@media screen and (max-width: 1000px){
    .landing-faq {
        width: 90%;
        padding: 5px;
        margin-top: 30px;
        top: 300px;
        font-size: 18px;
    }
}





