.orders-pack-container {
    background: #FFFFFF;
    border: 1px solid #ECEBED;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 30px 30px;
    margin-bottom: 10px;
}

@media screen and (max-width: 1000px){
    .orders-pack-container {
        padding: 0 !important;
        border: none !important;
        margin-bottom: 30px;
    }
}

.orders-pack-container-inner {
    width: 750px;
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 1000px){
    .orders-pack-container-inner {
        width: 100%;
        margin: 0 auto;
        margin-top: 50px;
        display: block;
    }
}

.box {
    height: auto;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #ECEBED;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 30px 30px;
    margin-bottom: 10px;
}

@media screen and (max-width: 1000px) {
    .box {
        width: 100%;
        padding: 3px 5px;
        border: none;
    }
}

.order-terms-container {
    margin-top: 34px;
}

.qr-code-container {
    margin: 0 auto;
    margin-top: 50px;
    text-align: center;
}

.qr-code-caption {
    font-family: JosefinSansLight, serif !important;
    font-weight: 300;
    font-size: 24px;
    line-height: 38px;
    margin-bottom: 12px;
}

.qr-code-address {
    font-size: 24px;
    line-height: 38px;
    margin-bottom: 18px;
}

@media screen and (max-width: 1000px) {
    .qr-code-address {
        font-size: 18px;
        line-height: 28px;
    }
}

.qr-code-link {
    margin-top: 50px;
}

.qr-code-link > a {
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    text-decoration: underline !important;
    color: #5B5B5B !important;
}

.receipt {
    width: 800px;
    margin: 0 auto;
}

@media screen and (max-width: 1000px){
    .receipt {
        width: 100%;
    }
}

.receipt > div:nth-child(1) {
    margin-bottom: 50px;
}

.receipt-data-table {
    margin-top: 50px;
}

.receipt-data-table > table > tbody > tr > td {
    border-bottom: none !important;
    cursor: default !important;
}

.receipt-footer {
    margin: 0 auto;
    margin-top: 100px;
    text-align: center;
    font-family: JosefinSansLight, serif;
    font-weight: 100;
    font-size: 16px;
    line-height: 34px;
}