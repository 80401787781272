.account-menu-container {
    width: 350px;
    min-width: 350px;
    max-width: 350px;
    height: 905px;
    background: #FFFFFF;
    border: 1px solid #ECEBED;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 30px 30px;
}

@media screen and (max-width: 1000px) {
    .account-menu-container {
        max-width: 100% !important;
        min-width: unset;
        width: 100%;
        height: auto;
        border: none !important;
        padding: 10px 10px;
    }
}

.account-menu-user-info {
    margin: 0 auto;
    text-align: center;
    height: 191px;
}

@media screen and (max-width: 1000px) {
    .account-menu-user-info {
        height: auto;
    }
}


.account-menu-user-info-avatar {
    border: 1px solid #A5AFFB;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    overflow: hidden;
    padding: 8px;
    box-sizing: border-box;
}

.account-menu-user-info-avatar > img {
    width: 83px;
    height: 83px;
    text-align: center;
}

@media screen and (max-width: 1000px) {
    .account-menu-user-info-avatar {
        width: 60px;
        height: 60px;
    }
    .account-menu-user-info-avatar > img {
        width: 50px;
        height: 50px;
    }
}

.account-menu-user-info-username {
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    color: #37406F;
    margin-top: 26px;
    margin-bottom: 3px;
}

@media screen and (max-width: 1000px) {
    .account-menu-user-info-username {
        font-size: 22px;
        line-height: 28px;
        margin-top: 9px;
        margin-bottom: 4px;
    }
}


.account-menu-user-info-email {
    font-size: 16px;
    line-height: 28px;
    color: #6771A2;
}


@media screen and (max-width: 1000px) {
    .account-menu-user-info-email {
        font-size: 16px;
        line-height: 22px;
    }
}


.account-menu {
    margin-top: 50px;
}

@media screen and (max-width: 1000px) {
    .account-menu {
        margin-top: 20px;
    }
}

.account-menu-item {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 40px;
    color: #37406F;
    padding: 6px 18px;
    margin-bottom: 14px;
    border-radius: 25px;
    cursor: pointer;
}


@media screen and (max-width: 1000px) {
    .account-menu-item {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 18px;
        line-height: 40px;
        color: #37406F;
        padding: 6px 18px;
        margin-bottom: 8px;
    }
}

.account-menu-item:hover {
    background: #F7F6FC;
}

.account-menu-item-active {
    background: #F7F6FC;
}

.account-menu-item > img {
    width: 25px;
    height: 40px;
    margin-right: 10px;
}