.bid-pack-item {
    width: 111px;
    height: 180px;
    background: #FFFFFF;
    border: 1px solid #ECEBED;
    box-sizing: border-box;
    /*box-shadow: 0 3px 2.62131px rgba(0, 0, 0, 0.25);*/
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    border-radius: 13px;
    text-align: center;
    padding-top: 22px;
    position: relative;
    cursor: pointer;
}

@media screen and (max-width: 1000px) {
    .bid-pack-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 120px;
        margin-bottom: 10px;
        padding: 22px 20px;
    }

    .bid-pack-item > div:nth-child(1) {
        width: 35%;
    }
    .bid-pack-item > div:nth-child(2) {
        width: 55px;
    }
    .bid-pack-item > div:nth-child(3) {
        width: 30%;
        text-align: center;
    }
}

.bid-pack-name {
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 8px;
}

@media screen and (max-width: 1000px){
    .bid-pack-name {
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 2px;
    }
}

.bid-pack-bids {
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 2px;
}

@media screen and (max-width: 1000px){
    .bid-pack-bids {
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 7px;
    }
}

.bid-pack-per-item{
    font-weight: 300;
    font-size: 10px;
    line-height: 10px;
    margin-bottom: 23px;
}

@media screen and (max-width: 1000px){
    .bid-pack-per-item{
        font-weight: 300;
        font-size: 12px;
        line-height: 12px;
        margin-bottom: 0;
    }
}

.bid-pack-price{
    font-weight: 300;
    font-size: 15px;
    line-height: 16px;
    margin-bottom: 13px;
}

@media screen and (max-width: 1000px){
    .bid-pack-price{
        font-weight: 300;
        font-size: 22px;
        line-height: 22px;
        margin-bottom: 10px;
    }
}


.bid-pack-buy-icon > img {
    width: 39px;
    height: 39px;
}

.bid-pack-discount {
    position: absolute;
    top: -30px;
    right: -15px;
    width: 50px;
    height: 50px;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
}

@media screen and (max-width: 1000px){
    .bid-pack-discount {
        position: initial;
        top: 0;
        right: 0;
        width: 55px;
        height: 55px;
        color: #FFFFFF;
        font-size: 14px;
        line-height: 14px;
        font-weight: bold;
    }
}

.bid-pack-discount-inner {
    position: relative;
}

.bid-pack-discount-inner > img {
    width: 50px;
    height: 50px;
}

.bid-pack-discount-data {
    position: absolute;
    top: 13px;
    left: 2px;
}