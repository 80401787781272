.payment-methods-container {
    margin-top: 42px;
}

.payment-methods-container > .caption {
    text-align: center;
}

.payment-methods-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-top: 14px;
}

@media screen and (max-width: 1000px){
    .payment-methods-inner {
        width: 300px;
    }
}


.payment-method {
    border: 1px solid #B8B8B8;
    box-sizing: border-box;
    border-radius: 10px;
    width: 150px;
    height: 100px;
    padding: 15px 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
}

@media screen and (max-width: 1000px){
    .payment-method {
        width: 90px;
        height: 60px;
        margin-bottom: 3px;
    }
}

.payment-method > img {
    max-width: 100%;
    max-height: 100%;
    z-index: -1;
}