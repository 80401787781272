.account-container {
    display: flex;
}

@media screen and (max-width: 1000px) {
    .account-container {
        max-width: 100%;
    }
    .account-container > div:nth-child(1) {
        margin: 0 auto !important;
    }
}

.account-container > div:nth-child(1) {
    margin-right: 30px;
    width: 100%;
}

.account-container > div:nth-child(2) {
    width: 100%;
}

.account-box-top {
    height: 172px;
    background: #FFFFFF;
    box-shadow: -3.67394e-16px 6px 18px rgba(16, 5, 54, 0.168627);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 30px 30px;
}

.account-content {
    font-size: 18px;
    line-height: 28px;
    color: #37406F;
}

.dashboard-items-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #D7D9F5;
    margin-top: 24px;
    padding-top: 30px;
    box-sizing: border-box;
}

@media screen and (max-width: 1000px) {
    .dashboard-items-container {
        max-width: 100%;
        border: none;
        margin-top: 13px;
    }
}

.dashboard-item {
    width: 195px;
    height: 236px;
    text-align: center;
    border-radius: 20px;
}

@media screen and (max-width: 1000px) {
    .dashboard-item {
        width: 32%;
        height: auto;
        padding: 20px 0;
        box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    }
}

.dashboard-box {
    height: auto;
    width: 720px;
    background: #FFFFFF;
    /*box-shadow: -3.67394e-16px 6px 18px rgba(16, 5, 54, 0.168627);*/
    border: 1px solid #ECEBED;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 30px 30px;
    margin-bottom: 10px;
}

@media screen and (max-width: 1000px) {
    .dashboard-box {
        width: 100%;
        padding: 0;
        border: none;
    }
}


.dashboard-item > img {
    width: 85px;
    height: 85px;
}

@media screen and (max-width: 1000px) {
    .dashboard-item > img {
        width: 45px;
        height: 45px;
    }
}


.dashboard-item-balance {
    font-weight: 600;
    font-size: 50px;
    line-height: 60px;
    color: #171D1C;
    margin-top: 7px;
}

@media screen and (max-width: 1000px) {
    .dashboard-item-balance {
        font-weight: 600;
        font-size: 26px;
        line-height: 28px;
        margin-top: 4px;
    }
}

.dashboard-item-caption {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #171D1C;
}

@media screen and (max-width: 1000px) {
    .dashboard-item-caption {
        font-size: 14px;
        line-height: 15px;
    }
}

.dashboard-pack-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 1000px) {
    .dashboard-pack-container {
        display: block;
    }
}

.account-share-link-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 25px;
    background: #FBFCFF;
    border: 1px solid rgba(55, 64, 111, 0.2);
    margin-top: 24px;
    margin-bottom: 49px;
    padding-left: 30px;
    box-sizing: border-box;
    color: #37406F;
}

@media screen and (max-width: 1000px) {
    .account-share-link-container {
        margin-top: 13px;
        margin-bottom: 29px;
        background: none;
        border: none;
        padding: 0;
        font-size: 12px;
    }
}

.account-share-container {
    margin-top: 24px;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1000px){
    .account-share-container {
        margin-top: 24px;
        display: block;
        text-align: center;
    }
}

.share-button {
    margin-left: 11px;
}

.account-share-caption {
    font-size: 24px;
    line-height: 36px;
    color: #323636;
}
@media screen and (max-width: 1000px) {
    .account-share-caption {
        font-size: 18px;
        line-height: 22px;
        color: #323636;
        margin-bottom: 13px;
    }
}

.account-share-box {

}

@media screen and (max-width: 1000px) {
    .account-share-box {
        box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
        border: 1px solid #ECEBED;
        padding: 20px 26px;
        margin-top: 20px;
    }
}

.personal-details-flex {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 1000px){
    .personal-details-flex {
        display: block;
    }
}

.personal-details-table {

}

.personal-details-table-item {
    display: flex;
    font-size: 18px;
    line-height: 28px;
    align-items: center;
    color: #37406F;
    margin-bottom: 25px;
}

@media screen and (max-width: 1000px){
    .personal-details-table-item {
        font-size: 16px;
        line-height: 26px;
    }
}

.personal-details-table-item > input {
    width: 200px;
    margin-left: 10px;
    font-size: 18px;
    line-height: 28px;
}

@media screen and (max-width: 1000px){
    .personal-details-table-item > input {
        width: 100%;
        margin-left: 10px;
        font-size: 16px;
        line-height: 26px;
    }
}

.personal-details-table-item.full-width > input {
    width: 100% !important;
    margin-left: 10px;
}


.personal-details-table-item > div:nth-child(1) {
    min-width: 110px;
    width: 110px;
    text-align: right;
}

.personal-details-buttons {
    text-align: center;
    margin-top: 40px;
}